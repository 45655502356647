import React from "react";
import styles from "./styles.module.css";
import { Link } from "gatsby";
import Button from "../../../components/Button";
import email1 from "../../../../../assets/img/email1.gif";
import email2 from "../../../../../assets/img/email2.gif";
import email3 from "../../../../../assets/img/email3.gif";
import email4 from "../../../../../assets/img/email4.gif";

export default function Synchronisez() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Synchronisez vos données et demeurez à jour</h1>
        <div className={styles.modernbricksmenu2}>
          <ul>
            <li>
              <Link to="/developpez/services-web/solutions-pour-le-cybercommerce/forfaits-cybercommerce/">
                Courriel classe affaires
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/forfaits-courriels/organisez/">
                Organisez
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/forfaits-courriels/synchronisez/">
                Synchronisez
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/hebergement-web/">
                Forfaits Web
              </Link>
            </li>
            <li>
              <Link to="/developpez/services-web/forfaits-courriels/">
                Forfaits courriel
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.row}>
        <table
          cellspacing="5"
          cellpadding="5"
          border="0"
          width="100%"
          className={styles.contentBox2}
        >
          <thead>
            <tr>
              <th>
                <h2>SyncJe pour Outlook</h2>
              </th>
              <th>
                <h2>SyncJe pour Outlook Express</h2>
              </th>
              <th>
                <h2>SyncJe pour BlackBerry </h2>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ backgroundColor: "#f1f1f1" }} id="t01">
                <strong>Caractéristiques </strong>
                <ul>
                  <li>Synchronisation du calendrier et du carnet d'adresses</li>
                  <li>Planificateur de synchronisation automatique</li>
                  <li>élimination des entrées dupliquées</li>
                  <li>Assistant de configuration </li>
                  <li>Suivi du journal de synchronisation </li>
                  <li>Soutien technique 24/7</li>
                </ul>
              </td>
              <td style={{ backgroundColor: "#f1f1f1" }}>
                <strong>Caractéristiques </strong>
                <ul>
                  <li>Synchronisation du carnet d'adresses</li>
                  <li>Planificateur de synchronisation automatique</li>
                  <li>élimination des entrées dupliquées</li>
                  <li>Assistant de configuration </li>
                  <li>Suivi du journal de synchronisation </li>
                  <li>Soutien technique 24/7</li>
                </ul>
              </td>
              <td style={{ backgroundColor: "#f1f1f1" }} colspan="2">
                <strong>Caractéristiques </strong>
                <ul>
                  <li>Synchronisation du calendrier et du carnet d’adresses</li>
                  <li>Planificateur de synchronisation automatique</li>
                  <li>Assistant de configuration </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td colspan="4"> </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#f1f1f1" }}>
                <strong>Compatibilité </strong>
                <ul>
                  <li>De Windows 95 à Vista</li>
                  <li>De Windows 97 à 2007</li>
                </ul>
              </td>
              <td style={{ backgroundColor: "#f1f1f1" }}>
                <strong>Compatibilité </strong>
                <ul>
                  <li>De Windows 95 à Vista</li>
                  <li>De Windows 97 à 2007</li>
                </ul>
              </td>
              <td style={{ backgroundColor: "#f1f1f1" }} colspan="2">
                <strong>Compatibilité </strong>
                <ul>
                  <li>OS Version 4.0.2 or higher</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
