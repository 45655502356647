import React from "react";
import Layout from "../../../../other/layout";
import Synchronisez from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/ForfaitsCourriels/Synchronisez";
import SEO from "../../../../other/seo";

export default function SynchronisezPage() {
  return (
    <Layout>
      <SEO title="Synchronisez vos données et demeurez à jour" />
      <Synchronisez />
    </Layout>
  );
}
